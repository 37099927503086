<template>
  <v-dialog v-model="warningShow" max-width="600" width="90%" @click:outside="close()">
    <v-card class="d-flex flex-column justify-center align-center pa-md-10 pa-5">
      <h2 class="primary--text mb-8">
        <slot name="title"></slot>
      </h2>
      <div class="font-weight-bold">
        <slot name="inputTitle"></slot>
      </div>
      <h3 class="primary--text mb-8">
        <slot name="content"></slot>
      </h3>
      <btn :buttonText="confirmText" :width="270" @clickBtn="confirm()"></btn>
    </v-card>
  </v-dialog>
</template>
<script>
import btn from '@/components/btn.vue'
export default {
  name: 'warning',
  props:{
    warningShow: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      
    }
  },
  components:{
    btn,
  },
  methods:{
    confirm(){
      this.$emit('confirm')
    },
    close(){
      this.$emit('close')
    },
  },
}
</script>